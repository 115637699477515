<template>
    <div class="row">
    <div class="hover-blue cursor-pointer col-md-7 d-flex align-items-start align-items-md-center px-2" @click="goBack">
        <div class="me-3 mt-2 mt-md-0">
            <icon-arrow :size="25" style="transform: rotate(180deg);" :color="'var(--dark-gray-200)'"/>
        </div>
        <div class="d-flex flex-column align-items-start ms-1">
            <h6 class="title-22 color-blue-700 mb-1">{{$t("account.my_devices_and_sessions")}}</h6>
            <h6 class="subtitle-16 mb-0">{{$t("account.description_devices")}}</h6>
        </div>
    </div>
    <div class="col-md-5 d-flex align-items-end justify-content-end">
        <second-button :title="'account.receive_alerts'" class="me-3" :icon="true">
            <icon-alert :size="12" class="me-2"/>
        </second-button>
        <main-button :title="'account.logout'" :icon="true">
            <icon-login :size="14" class="me-2"/>
        </main-button>
    </div>
    <div class="col-md-12 mt-4">
        <table class="table table-hover">
            <thead style="border-bottom:2px solid var(--gray-100) !important">
                <tr>
                    <th class="weight-600 d-flex align-items-center border-0"> 
                        <main-checkbox class="me-3" :id="'select-all'" :value="0"/> {{$t("account.linked_devices")}}
                    </th>
                    <th class="text-center weight-600">{{$t("account.date")}}</th>
                    <th class="text-center weight-600">{{$t("account.receive_alerts")}}</th>
                    <th class="text-center weight-600">{{$t("account.isnt_that_you")}}?</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="list in 4" :key="list.name" id="select-list">
                    <td class="align-middle" id="open">
                        <div class="d-flex">
                            <div> 
                                <main-checkbox :id="'select-wishlist-' + list._id" class="me-3"/>
                            </div>
                            <div class="cursor-pointe d-flex flex-column" @click="$emit('listSelected', list)">
                                <h6 class="title-14 mb-0">Samsumg Galaxy s8+</h6>
                                <span class="subtitle-13 color-dark-gray-200">Santiago, baracoa Rep.Dom</span>
                                <div class="subtitle-13 color-dark-gray-200 d-flex align-items-center"> <img class="me-1" src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Google_Chrome_icon_%28September_2014%29.svg/1024px-Google_Chrome_icon_%28September_2014%29.svg.png" alt="" srcset="" width="14"> Desde Chrome</div>
                            </div>
                        </div> 
                    </td>
                    <td class="align-middle text-center responsive-column" id="open">
                        <div class="d-flex flex-column align-items-center">
                            <span class="subtitle-14 color-dark-gray-300"><icon-check-circle :size="15" class="me-2"/>Activa </span>
                            <span class="subtitle-14 color-dark-gray-300">Hoy 10:30 am</span>
                        </div>
                    </td>
                    <td class="align-middle title-14 weight-700 text-center responsive-column" id="open">
                        <main-switch class="justify-content-center" :id="'select-public'"/>
                    </td>
                    <td class="align-middle title-14 weight-700 text-center responsive-column">
                        <main-button :title="'account.logout'">
                        </main-button>  
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
        <!-- <button @click="getSessions">
            Test
        </button> -->
        <!-- <button @click="getAgent">
            User Agent
        </button> -->
    </div>
</template>

<script>
import {Auth} from 'aws-amplify'
import { mapGetters } from "vuex"
import iconArrow from '../../../common/svg/iconArrow.vue'
import SecondButton from '../../../common/components/buttons/SecondButton.vue'
import MainButton from '../../../common/components/buttons/MainButton.vue'
import IconAlert from '../assets/svg/iconAlert.vue'
import IconLogin from '../assets/svg/iconLogin.vue'
import MainCheckbox from '../../../common/components/checkbox/MainCheckbox.vue'
import MainSwitch from '../../../common/components/switch/MainSwitch.vue'
import IconCheckCircle from '../assets/svg/iconCheckCircle.vue'
import axios from "axios";
import UAParser from "ua-parser-js"
export default {
    components: { iconArrow, SecondButton, MainButton, IconAlert, IconLogin, MainCheckbox, MainSwitch, IconCheckCircle },
    name: "Devices",
        IconLogin,
    computed:{
        ...mapGetters({
            user:"auth/userData",
        }),
    },
    methods: {        
        goBack(){
            this.$router.go(-1)
        },
        async getSessions(){
            const devices = await Auth.fetchDevices()
            console.log(devices);
        },
        async getAgent(){
            const getUser = new UAParser(this.$ua.value, {})
            const agent = getUser.getResult();
            const location = await this.locatorButtonPressed()
            const uuid = this.generateUUID()
            let userAgent = {
                device: agent.os,
                browser: agent.browser,
                location,
                uuid:uuid,
            }
            localStorage.setItem("userAgent", JSON.stringify(userAgent))
        },
        async locatorButtonPressed() {
            try {
                let lat = null;
                let long = null;
                var promise = new Promise(function (resolve){
                    navigator.geolocation.getCurrentPosition(function(position){
                        lat = position.coords.latitude
                        long = position.coords.longitude
                        resolve({lat,long});
                    })
                })
                const location = await promise
                const address = await this.getStreetAddressFrom(location.lat, location.long)
                // console.log(address.address);
                // console.log(location);
                return address.address
            } catch (error) {
                console.log(error);
            }
        },
        async getStreetAddressFrom(lat, long) {
                try {
                    // var MAIN_URL = 'https://nominatim.openstreetmap.org/reverse?format=json&lat='+latitud+'&lon='+longitud;
                    const address = await axios.get('https://nominatim.openstreetmap.org/reverse?format=json&lat='+lat+'&lon='+long)
                    // console.log(address.data);
                    return address.data
                    // const xhr = new XMLHttpRequest();
                    // xhr.open('GET', MAIN_URL);
                    // xhr.responseType = 'json';
                    // xhr.onload = function(e) {
                    // if (this.status == 200) {
                    //     var dataResult = this.response; // JSON response
                    //     let direction_name = dataResult['display_name'];
                    //     let addressResults = dataResult['address'];
                    //     let city_name = addressResults['city'];
                    //         document.getElementById("geocomplete").value = direction_name;
                    //         get_city_from_name(city_name);
                    //     }
                    // };
                    // xhr.send();
                    // var { data } = await axios.get(
                    // "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
                    //     lat +
                    //     "," +
                    //     long +
                    //     "&key=" + process.env.VUE_APP_API_GOOGLE_MAPS_KEY 
                    // );
                    // if(data.error_message) {
                    // console.log(data.error_message)
                    // } else {
                    // this.address = data.results[0].formatted_address;
                    // }
                } catch (error) {
                    console.log(error.message);
                } 
            },
        generateUUID() { 
            // Public Domain/MIT
            var d = new Date().getTime();
            if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
                d += performance.now(); //use high-precision timer if available
            }
            var newGuid = this.user.id  + '-xxxx-xxx-yxxx-xxxx'.replace(/[xy]/g, function (c) {
                var r = (d + Math.random() * 16) % 16 | 0;
                d = Math.floor(d / 16);
                return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
            return newGuid;
        }
    }
}
</script>

<style scoped>
    .responsive-column{
        padding: 30px 10px;
    }
    td{
        width: 25%;
    }
    .table{
        --bs-table-hover-bg: #f4f7f93d
    }
    .table>:not(caption)>*>*{
        border-color: var(--gray-100);
    }
    .hover-blue:hover svg{
        fill: var(--blue-600);
    }
    .hover-blue svg{
        transition: var(--transition-1);
    }
</style>