<template>
    <icon-base :width="size + 'px'" :fill="color" viewBox="0 0 14.135 13.621">
        <g id="Login_icon" data-name="Login icon" transform="translate(0.15 0.15)">
            <path id="Union_17" data-name="Union 17" d="M1.06,9.1A.451.451,0,1,1,1.9,8.765,5.71,5.71,0,0,0,12.932,6.66,5.71,5.71,0,0,0,1.9,4.557a.451.451,0,1,1-.839-.333A6.611,6.611,0,0,1,13.835,6.66a6.647,6.647,0,0,1-6.617,6.661A6.581,6.581,0,0,1,1.06,9.1ZM6.447,9.4a.454.454,0,0,1,0-.642L8.083,7.115H.45a.454.454,0,0,1,0-.908H8.085L6.447,4.56a.456.456,0,0,1,0-.643.449.449,0,0,1,.638,0L9.492,6.339a.46.46,0,0,1,.087.123l0,0,0,.006a.455.455,0,0,1-.1.521L7.085,9.4a.446.446,0,0,1-.638,0Z" fill="#fff" stroke="#fff" stroke-width="0.3"/>
        </g>
    </icon-base>
</template>
<script>
import iconBase from '../../../../common/components/iconBase.vue'
export default {
    components: { iconBase },
    name: 'iconLogin',
    props:{
    size: {
        type: Number,
        default: 20
    },
        color: {
        type: String,
        default: 'var(--main-navy)'
    },
    
    }
}
</script>
<style scoped>
</style>